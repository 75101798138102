<template>
  <div class="Auth">
    <AuthComp msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import AuthComp from '@/components/Auth2.vue'

export default {
  name: 'Home',

  components: {
    AuthComp
  }
}
</script>
