<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <LoadLog msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadLog from '@/components/LoadLog.vue'

export default {
  name: 'Home',

  components: {
    LoadLog
  }
}
</script>
