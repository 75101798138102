<template>
  <v-app>
    <v-app-bar
      app
      style="background-color:#33383d;"
      dark
    >
      <div class="d-flex align-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" fill="#ff4f1f" viewBox="0 0 16 16" aria-hidden="true"><path d="M10.883 3.333h.067c.867 0 1.6-.733 1.6-1.6 0-.933-.733-1.6-1.6-1.6-.867 0-1.6.733-1.6 1.6 0 .867.667 1.534 1.533 1.6zM10.95 1c.4 0 .667.333.667.667 0 .333-.333.667-.667.667h-.067c-.4 0-.667-.333-.667-.733.001-.248.188-.601.734-.601zM9.883 4H5.817C4.35 4 3.15 5.2 3.15 6.667s1.2 2.667 2.667 2.667h4.067c1.467 0 2.667-1.2 2.667-2.667S11.35 4 9.883 4zm0 4.2H5.817c-.867 0-1.6-.733-1.6-1.6 0-.867.733-1.6 1.6-1.6h4.067c.867 0 1.6.733 1.6 1.6a1.623 1.623 0 0 1-1.601 1.6zm0 1.933H8.15c-1.467 0-2.667 1.2-2.667 2.667s1.2 2.667 2.667 2.667h1.733c1.467 0 2.667-1.2 2.667-2.667s-1.2-2.667-2.667-2.667zm0 4.267H8.15c-.867 0-1.6-.733-1.6-1.6 0-.867.733-1.6 1.6-1.6h1.733c.867 0 1.6.733 1.6 1.6 0 .867-.733 1.6-1.6 1.6z"></path></svg>

        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo_vilog.svg')"
          transition="scale-transition"
          width="110"
        />
        <!--<v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/logo-by-esmt-white.svg')"
          transition="scale-transition"
          width="110"
        />-->
      </div>

      <v-spacer></v-spacer>

      <v-btn
        target="_blank"
        text
        v-if="this.$route.name=='App' || this.$route.name=='Auth'"
      >
        <span class="mr-2">Docs</span>
        <v-icon>mdi-table</v-icon>
      </v-btn>

      <v-btn
        @click="reloadApp()"
        target="_blank"
        text
        v-if="this.$route.name!='Token'"
      >
        <span class="mr-2">Restart</span>
        <v-icon>mdi-redo</v-icon>
      </v-btn>
      <v-btn
        @click="resetApp()"
        target="_blank"
        text
        v-if="this.$route.name=='App'"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  methods:{
    reloadApp(){
      window.location.reload()
    },

    resetApp(){
      localStorage.removeItem('esmtAppLogs')
      this.$router.push({ name: 'Token' })
    }
  }
};
</script>

<style>
.genesysCloudBackColor2{
  background-color: #33383d;
}
.genesysCloudBackColor{
  background-color:#444a52;
}

.esmtGreenColor{
  background-color:#013c4c;
}

</style>
