<template>
  <div class="Auth">
    <TokenComp msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import TokenComp from '@/components/Token.vue'

export default {
  name: 'Token',

  components: {
    TokenComp
  }
}
</script>
