<template>
        
    <v-container v-if="logLoaded" fluid>
        <v-card>
    <!--<v-tabs
      v-model="tab"
      style="background-color:#33383d;"
      dark
      centered
      color="deep-orange darken-1"
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        Logs
        <v-icon>mdi-eye</v-icon>
      </v-tab>-->

      <!--<v-tab href="#tab-2">
        Analitycs
        <v-icon>mdi-database</v-icon>
      </v-tab>

      <v-tab href="#tab-3">
        Nearby
        <v-icon>mdi-account-box</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">-->
        <v-card flat>
          
          <v-expansion-panels v-if="arrayIDsObjects.length>0">
            <v-expansion-panel
            v-for="(item,i) in arrayIDsObjects"
            :key="i"
            >
            <!--<v-expansion-panel active
            >-->
            <v-expansion-panel-header>
                <!--<v-chip
                color="red lighten-4"
                >-->
                <v-chip
                color="green lighten-4"
                >
                Interacción {{item.log.conversationId}} <v-chip x-small class="ml-2">{{item.mediaType}}</v-chip>
                <v-icon @click="downloadLog(i)" style="position:absolute; clear:both; display:block; float:right; margin:0; right:10px;">mdi-download</v-icon>
                </v-chip>
                
            </v-expansion-panel-header>
            
            <v-expansion-panel-content>
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                    <h4>Timeline de la interacción <v-chip class="py-0">{{Object.keys(item.allSegments).length}}</v-chip></h4>
                    <v-card
                        class="mx-auto"
                        tile
                    >

                    <div class="timeline" v-if="Object.keys(item.allSegments).length>0">
                        <!--.slice().reverse()-->
                        <div v-for="(clip, a) in segmentsSorted(item.allSegments)" :key="clip.id" :style="{'width': widthAllClips('Segments', i)+'%', 'min-width': '100px', 'box-shadow': '0px 4px 12px #333333;'}" class="clip" :class="(clip.purpose == 'customer'||clip.purpose == 'user') ? 'pink lighten-4':'' || clip.purpose == 'external' ? 'pink lighten-2':'' || clip.purpose == 'acd' ? 'deep-orange lighten-3':'' || clip.purpose == 'ivr' ? 'amber lighten-2':'' || clip.purpose == 'workflow' ? 'teal accent-1':'' || clip.purpose == 'agent' ? 'light-blue accent-'+(clip.nAgent)+'':''">
                            <!--<span v-if="clip.name=='tAbandon'" style="background-color:#FFF; position:absolute; clear:both; margin:0; z-index:1; right:0;"><v-icon class="red--text" v-text="'mdi-alert'"></v-icon></span>-->
                            
                            <v-tooltip
                                
                                top
                                >
                                <template v-slot:activator="{ on, attrs }">
                            
                                    <v-chip v-bind="attrs"
                                    v-on="on"
                                    class="p-0" style="border-radius:0px 0px 16px; font-size:12px;">
                                    <strong>
                                    {{a+1}}: {{clip.purpose}} <v-icon v-if="clip.segmentD.segmentType=='delay'" class="red--text" v-text="'mdi-cancel'"></v-icon> <!--<v-icon v-if="clip.name=='tHandle' || clip.name=='tAnswered'" class="green--text" v-text="'mdi-check-bold'"></v-icon><v-icon v-if="clip.name=='tAlert' || clip.name=='tNotResponding'" class="red--text" v-text="'mdi-alert'"></v-icon>-->
                                    </strong>
                                    </v-chip>

                                    <p class="clip-p black--text">Tend: <strong>{{moment(clip.timeE).format("hh:mm:ss")}}</strong></p>
                                    <p class="clip-p black--text">Tinit: <strong><u>{{moment(clip.timeS).format("hh:mm:ss")}}</u></strong></p>
                                    <p class="clip-p black--text">Acción: <strong>{{clip.segmentD.segmentType}}</strong></p>
                                    <!--<p class="clip-p black--text">{{clip.emitDate}}</p>
                                    <p v-if="clip.nParticipant !=null " class="clip-p black--text">Nombre: {{clip.nParticipant}}</p>-->

                                </template>
                                {{clip}}
                            </v-tooltip>
                        </div>
                    </div>

                    </v-card>

                    <hr class="mt-4 mb-2"/>

                    <h4>Timeline acciones por participante <v-chip class="p-2">{{Object.keys(item.allMetrics).length}}</v-chip></h4>
                    <v-card
                        class="mx-auto"
                        tile
                    >

                    <div class="timeline" v-if="Object.keys(item.allMetrics).length>0">
                        <!--.slice().reverse()-->
                        <div v-for="(clip, a) in metricsSorted(item.allMetrics)" :key="clip.id" :style="{'width': widthAllClips('Metrics', i)+'%', 'min-width': '100px', 'box-shadow': '0px 4px 12px #333333;'}" class="clip" :class="(clip.purpose == 'customer' || clip.purpose == 'user') ? 'pink lighten-4':'' || clip.purpose == 'external' ? 'pink lighten-2':'' || clip.purpose == 'acd' ? 'deep-orange lighten-3':'' || clip.purpose == 'ivr' ? 'amber lighten-2':'' || clip.purpose == 'workflow' ? 'teal accent-1':'' || clip.purpose == 'agent' ? 'light-blue accent-'+(clip.nAgent)+'':''">
                            <!--<span v-if="clip.name=='tAbandon'" style="background-color:#FFF; position:absolute; clear:both; margin:0; z-index:1; right:0;"><v-icon class="red--text" v-text="'mdi-alert'"></v-icon></span>-->
                            
                            <v-tooltip
                                
                                top
                                >
                                <template v-slot:activator="{ on, attrs }">
                            
                                    <v-chip v-bind="attrs"
                                    v-on="on"
                                    class="mt-0" style="border-radius:0px 0px 16px; font-size:12px;"><strong>
                                    {{a+1}}: {{clip.purpose}} {{clip.nAgent}} <v-icon v-if="clip.name=='tAbandon'" class="red--text" v-text="'mdi-cancel'"></v-icon> <v-icon v-if="clip.name=='tHandle' || clip.name=='tAnswered'" class="green--text" v-text="'mdi-check-bold'"></v-icon><v-icon v-if="clip.name=='tAlert' || clip.name=='tNotResponding'" class="red--text" v-text="'mdi-alert'"></v-icon>
                                    </strong>
                                    </v-chip>
                                    <p class="clip-p black--text">Evento: <strong>{{clip.name}}</strong></p>
                                    <p class="clip-p black--text">T: <strong>{{clip.emitDate}}</strong></p>
                                    <p v-if="clip.nParticipant !=null " class="clip-p black--text">Nombre: <strong>{{clip.nParticipant}}</strong></p>

                                </template>
                                {{clip}}
                            </v-tooltip>
                        </div>
                    </div>

                    </v-card>
                    
                    
                    <hr class="mt-4 mb-4"/>

                    <v-card
                        class="mx-auto"
                        tile
                    >
                        <v-card-title :style="interaccionAbandonada ? 'background-color:red; color:white;' : 'background-color: #f3f3f3;'">
                            <v-icon
                                small
                                left
                                :style="'color:white;'"
                                v-if="interaccionAbandonada"
                            >
                                mdi-cancel
                            </v-icon>
                            <v-icon
                                large
                                left
                                :style="'color:green;'"
                                v-else
                            >
                                mdi-check-bold
                            </v-icon>
                            <span class="font-weight-light" style="font-size:14px;">Log {{item.log.conversationId}}</span>
                        </v-card-title>
                        <v-list dense>
                            <v-subheader>RESUMEN DEL LOG</v-subheader>
                            <v-list-item-group
                                v-model="selectedItem"
                                color="primary"
                            >
                                <v-list-item v-if="interaccionAbandonada">
                                    <v-list-item-icon>
                                        <v-icon :style="!interaccionAbandonada ? 'color:green' : 'color:red'" v-text="'mdi-cancel'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title :style="!interaccionAbandonada ? 'color:green' : 'color:red'" v-text="'INTERACCION ABANDONADA'"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon v-text="'mdi-clock'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="'Tiempo de la interacción: '+item.tiempoInteraccion"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <hr/>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon style="color:blue;" v-text="'mdi-clock-remove'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:blue;" v-text="'Tiempo en hold de la interacción: '+item.THOLD"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <hr/>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon v-text="'mdi-clock'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="'Intervalo completo del(los) agente(s): '+item.TTAI"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon style="color:green;" v-text="'mdi-clock'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:green;" v-text="'Intervalo efectivo del(los) agente(s): '+item.TO"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon v-text="'mdi-account-circle'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="'Agentes en la interacción: '+item.numeroDeAgentes"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list style="padding-left:5%;">
                                    <v-list-item v-for="(agente, a) in item.agentsObject" :key="agente.id">
                                        <div v-if="agente.tNotResponding">
                                            <small>
                                            <v-icon style="color:red;" v-text="'mdi-alert'"></v-icon>
                                            <span style="color:red;">Agente {{(a+1)}} no respondío la interacción</span>
                                            | 
                                            </small>
                                        </div>

                                        <div v-if="agente.disconnectType == 'system'">
                                            <small>
                                            <v-icon style="color:red;" v-text="'mdi-cancel'"></v-icon>
                                            <span style="color:red;" >{{'Agente '+(a+1)+' desconectado por: '+agente.disconnectType}}</span>
                                            | 
                                            </small>
                                        </div>
                                        
                                        <div v-if="agente.tAnswered || agente.tTalk">
                                            <small>
                                            <v-icon style="color:green;" v-text="'mdi-check-bold'"></v-icon>
                                            <span style="color:green;">{{'Agente '+(a+1)+' contestó la llamada'}}</span>
                                            | 
                                            </small>
                                        </div>

                                        <div v-if="agente.wrapup">
                                            <small>
                                            <v-icon style="color:green;" v-text="'mdi-check-bold'"></v-icon>
                                            <span style="color:green;">{{'Agente '+(a+1)+' ejecutó código de cierre'}}</span>
                                            | </small>
                                        </div>
                                    </v-list-item>
                                </v-list>
                                <hr/>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon style="color:#F30;" v-text="'mdi-clock'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:#F30;" v-text="'Tiempo de la interacción en ACD: '+item.TACD"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                
                                <v-list style="padding-left:5%;">
                                    <v-list-item v-for="(acd, a) in item.acdObject" :key="acd.id">
                                        <v-list-item-icon v-if="acd.disconnectType == 'transfer'">
                                            <v-icon style="color:green;" v-text="'mdi-check-bold'"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content v-if="acd.disconnectType == 'transfer'">
                                            <v-list-item-title style="color:green;" v-text="'Transferencia #'+(a+1)+' a agente.'"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-icon v-if="acd.tAbandon">
                                            <v-icon style="color:red;" v-text="'mdi-cancel'"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content v-if="acd.tAbandon">
                                            <v-list-item-title style="color:red; border:1px solid red" v-text="'Transferencia #'+(a+1)+' desconectó la interacción (Desconexión tipo '+acd.disconnectType+')'"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                
                                <div v-for="wrapup in item.allWrapUpCodes" :key="wrapup.id">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon style="color:#F30;" v-text="'mdi-clock'"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title style="color:#F30;" v-text="'Códigos de conclusión - '+wrapup.participant"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    
                                    <v-list-item style="padding-left:5%;" v-for="(wrapupD, b) in wrapup.wrapup" :key="wrapupD.id">
                                        <v-list-item-icon>
                                            <v-icon style="color:green;" v-text="'mdi-check'"></v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title style="color:green;" v-text="'Código de conclusión #'+(b+1)+' - '+wrapupD.name"></v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>
                                </div>

                                <hr/>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-icon style="color:purple;" v-text="'mdi-clock'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:purple;" v-text="'Tiempo del externo en la interacción: '+item.TTIC"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <hr v-if="item.NUERROR>0"/>
                                <v-list-item v-if="item.NUERROR>0">
                                    <v-list-item-icon>
                                        <v-icon style="color:red;" v-text="'mdi-alert-octagram-outline'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:red;" v-text="'# de errores en Edge: '+item.NUERROR"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <hr v-if="item.callback==true"/>
                                <v-list-item v-if="item.callback==true">
                                    <v-list-item-icon>
                                        <v-icon style="color:magenta;" v-text="'mdi-room-service'"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title style="color:magenta;" v-text="'Callback detectado'"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                    
                    <hr class="mt-4 mb-4"/>
                    
                    <v-card
                        class="mx-auto"
                        tile
                    >
                        <v-card-title style="background-color:#e3e3e3;">
                            <v-icon
                                large
                                left
                            >
                                mdi-twitter
                            </v-icon>
                            <span class="text-h6 font-weight-light">Detalles del log</span>
                            <v-btn class="m-0 ml-auto" @click="downloadLog(i)"><v-icon>mdi-download</v-icon></v-btn>
                        </v-card-title>
                        <v-list v-for="(item, i) in item.log" :key="i">

                        <v-list v-if="isArray(item) !== true && isObject(item) !== true">
                            <v-list-item>
                                <v-list-item-icon>
                                <v-icon v-if="i == 'conversationEnd'">mdi-clock-time-nine</v-icon>
                                <v-icon v-else-if="i == 'conversationStart'">mdi-clock-time-nine-outline</v-icon>
                                <v-icon v-else-if="i == 'conversationId'">mdi-card-account-details</v-icon>
                                <v-icon v-else-if="i == 'mediaStatsMinConversationMos'">mdi-card-account-details</v-icon>
                                <v-icon v-else-if="i == 'mediaStatsMinConversationRFactor'">mdi-play-network</v-icon>
                                <v-icon v-else-if="i == 'originatingDirection'">mdi-directions-fork</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="i"></v-list-item-title>
                                    <v-list-item-subtitle v-text="item"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-list v-else-if="i == 'divisionIds'">
                            <v-list-item>
                                <v-list-item-icon>
                                <v-icon>mdi-home</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="i"></v-list-item-title>
                                   <v-list-item v-for="(itemObj) in item"
                                                    :key="itemObj.id"
                                    >
                                        <v-list-item-subtitle v-text="itemObj"></v-list-item-subtitle>
                                   </v-list-item>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-list v-else-if="isArray(item) == true">
                            <v-list-group
                                prepend-icon="mdi-account-circle"
                            >
                                <template v-slot:activator>
                                    <v-list-item-title v-text="i"></v-list-item-title>
                                    
                                </template>
                                
                                <v-list-group v-for="(subitem, subI) in item"
                                    :key="subI"
                                    :value="true"
                                    no-action
                                    sub-group
                                >
                                    <template v-slot:activator>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="subitem.participantId"></v-list-item-title>
                                            <v-list-item-subtitle v-if="subitem.participantName" v-text="subitem.purpose+': '+subitem.participantName"></v-list-item-subtitle>
                                            <v-list-item-subtitle v-else v-text="subitem.purpose"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                    <!--<v-list-group v-for="(subitem2, subI2) in subitem" :key="subI2">-->
                                    <v-list v-for="(subitem2, subI2) in subitem" :key="subI2">
                                        <v-list-item style="background-color:#f3eded; padding:10px 30px" v-if="isObject(subitem2[0]) !== true" class="p-0">
                                            <v-list-item-title v-text="subI2"></v-list-item-title>
                                            <v-list-item-subtitle v-text="subitem2"></v-list-item-subtitle>
                                        </v-list-item>

                                        <v-list-item v-else>
                                            <v-list>
                                                <v-list-item-title v-text="subI2"></v-list-item-title>
                                                <v-list-item v-for="(subitem3, subI3) in subitem2[0]"
                                                    :key="subI3"
                                                    sub-group
                                                >
                                                        <v-list-item style="background-color:#e3e3e3;" class="mt-1">
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="subI3"></v-list-item-title>
                                                                <v-list-item-subtitle v-if="isArray(subitem3) !== true" v-text="subitem3"></v-list-item-subtitle>
                                                                <div v-if="isArray(subitem3) === true">
                                                                <v-list-group  v-for="(subitem4, subI4) in subitem3" :key="subI4" style="background-color:#f9e4f7;" class="mt-1"
                                                                    no-action
                                                                    sub-group
                                                                >
                                                                        <template v-slot:activator>
                                                                            <v-list-item-content :style="subitem4.name=='tAbandon' ? 'border:2px solid red;' : 'border:none;'">
                                                                                <v-list-item-title v-if="subitem4.segmentType" v-text="subitem4.segmentType"></v-list-item-title>
                                                                                <v-list-item-title v-else-if="subitem4.name" v-text="subitem4.name"></v-list-item-title>
                                                                                <v-list-item-title v-else v-text="subI4"></v-list-item-title>

                                                                            </v-list-item-content>
                                                                        </template>
                                                                        <v-list>
                                                                            <v-list-item>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-subtitle v-html="subitem4"></v-list-item-subtitle>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                </v-list-group>
                                                                </div>
                                                            </v-list-item-content>
                                                            
                                                        </v-list-item>

                                                        
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item>
                                    </v-list>
                                    
                                </v-list-group>
                            </v-list-group>
                            </v-list>
                        </v-list>
                    </v-card>
                </v-card>
            </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </v-card>
      <!--</v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <v-card-text>Como</v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card flat>
          <v-card-text>Estas</v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>-->
  </v-card>
        
        

        
        
        <!-- Menu documentacion
        <v-card
            class="pa-2"
            outlined
            tile
            v-if="optionS == 1"
        >
            <h3>Sección en construcción</h3>

            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam a purus id sem pellentesque ultricies. In sit amet arcu non elit sagittis finibus. Ut ullamcorper aliquet tempor. Suspendisse sed eros turpis. Integer non interdum massa, at sagittis ex. Cras erat tortor, ultrices vitae eros pellentesque, fringilla sollicitudin lectus. Praesent accumsan eros non quam vulputate, in congue augue efficitur. Aliquam in neque ligula. Etiam bibendum risus rhoncus eros vehicula, non cursus mauris cursus. Suspendisse potenti. Vestibulum massa nisi, molestie non finibus sed, euismod eu eros. In semper consectetur tristique. Pellentesque arcu risus, consectetur et fermentum vel, volutpat ut dui. Duis sed ex consequat, faucibus augue sit amet, venenatis augue. Quisque lacinia ipsum vel congue lobortis. Aenean sit amet urna non dolor tempus pellentesque a varius nulla.</p>

            <p>Vestibulum tristique purus eget est dignissim consequat. Aliquam egestas non ligula at condimentum. Nunc vitae orci tristique, luctus ipsum ac, lobortis erat. Ut interdum libero ac turpis imperdiet, eu aliquet ligula ultrices. Nam scelerisque et nulla molestie viverra. Nulla hendrerit at leo vel dapibus. Nulla rhoncus fermentum lacus, nec euismod tortor. Suspendisse fermentum odio eget porta iaculis. Quisque eget interdum justo.</p>

            <p>Cras vitae elit nec tortor placerat semper. Phasellus tempus in ex sed suscipit. Vestibulum tempus laoreet nisl, venenatis rhoncus nisi convallis et. In dictum dignissim blandit. Cras ultricies venenatis luctus. Aenean imperdiet, magna non hendrerit congue, dolor nisi gravida augue, vitae auctor erat tellus eu lectus. Donec tempor dapibus iaculis. Nunc dolor nulla, aliquam ac justo in, vestibulum ultrices magna.</p>

            <p>Praesent interdum sapien fringilla ullamcorper fermentum. Cras quis lorem et nulla iaculis fringilla. Curabitur dapibus quis risus vel tempor. Vestibulum quis nisi ipsum. Suspendisse luctus nibh et blandit aliquet. Duis vitae lectus et dolor consequat commodo. Nam at vulputate quam, nec fermentum lectus.</p>

            <p>Vestibulum venenatis magna eget dolor venenatis fermentum. Donec gravida lorem in dui eleifend, eu tristique nibh sollicitudin. Fusce condimentum eros ac dui dapibus, non tempus justo auctor. Ut dignissim cursus lobortis. Suspendisse semper convallis felis, ac ornare nisl aliquet aliquam. Pellentesque purus odio, ultrices porttitor ex vel, varius aliquet nibh. Sed gravida sed orci non sodales. Sed bibendum tortor lectus. In at nisi id sem viverra tempor sed sed lorem. Nunc vestibulum lobortis sodales. Nunc maximus enim vitae fermentum hendrerit. Sed ut ligula in lorem facilisis mattis. Curabitur a est leo.</p>
        </v-card>

        <v-card
            class="pa-2"
            outlined
            tile
            v-if="optionS == 2"
        >
            <h3>Sección en construcción</h3>
        </v-card>-->
        
    </v-container>

    <v-container v-else fluid>  
        <!--<v-file-input
            accept="application/JSON"
            label="Op 1: Load JSON log file"
            @change="previewFiles"
            ref="myFiles"
        ></v-file-input>-->

        <v-alert v-if="errorConsult"
            border="left"
            color="red"
            text
            type="error"
            dismissible
            >
            {{errorConsultText}}
            
            </v-alert>

        <!--<v-text-field
        clearable
        clear-icon="mdi-close-circle"
        label="Op1: Paste id interaction (call or chat)"
        v-model="textPasteID"
        @change="consultIDInteraction"
        ></v-text-field>-->

        <v-textarea
        clearable
        clear-icon="mdi-close-circle"
        label="Paste a conversation ID, or multiples IDs separates with coma (,). NO white spaces!"
        v-model="textPasteJSONArray"
        @change="convertTextToArray"
        ></v-textarea>

        <!--<v-textarea
        clearable
        clear-icon="mdi-close-circle"
        label="Op3: Paste JSON log"
        v-model="textPasteJSON"
        @change="pasteJSONtextJ()"
        ></v-textarea>-->

        
    </v-container>
</template>
<script>
//import dataJSONdefault from '../assets/js/data.json'
import moment from 'moment'
import _ from 'lodash'
import axios from 'axios'
import fileDownload from 'js-file-download'

export default {
    name: 'LoadLog',
    props:{
        msg:String
    },
    /*watch:{
        myJson: function(){
            this.analizandoLog()
        }
    },*/
    
    data(){
              return{
                tokenGC:'',
                theApp:{},
                logLoaded:false,
                auth:'',
                myJson: {},
                files: null,
                tab: null,
                textPasteJSON: null,
                textPasteID: null,
                allWrapUpCodes:[],

                textPasteJSONArray: null,
                arrayIDs:[],
                arrayIDsObjects:[],
                arrayIDsObjectsN:0,
                
                cierreDeInteraccion: 'Analizando...',
                interaccionAbandonada: false,
                codigoDeCierreEjecutado: false,
                numeroDeAgentes:0,
                tiempoInteraccion: '00:00:00',
                agentsObject:[],
                acdObject:[],
                allMetrics:[],
                allMetricsN:0,
                allSegments:[],
                allSegmentsN:0,
                customerObject:[],
                errorConsult: false,
                errorConsultText: '',
                options: [
                'Resultado', 'Documentación'
                ],
                arrayIDsObjectOriginalResult:[],
                optionS:'',
                TO: '00:00:00',
                TTAI: '00.00:00',
                TACD: '00:00:00',
                TTIC: '00.00:00',
                THOLD: 0,
                selectedItem:'',
                tokenActive: false,
                showTL1:'',
                showTL2:''
              }
          },
    computed: {
        
    },
    methods: {

        downloadLog(k=null){
            //let date  = Date.now();
            let r = (Math.random() + 100000).toString(36).substring(10);
            let nameFile = ''
            let i = 0
            let logFile = ''

            if(k==null){
                nameFile = moment()+'-'+r+'.txt'
                for(i=0; i<this.arrayIDsObjectOriginalResult.length; i++){
                    let JSONconverter = JSON.stringify(this.arrayIDsObjectOriginalResult[i])
                    logFile = logFile+JSONconverter
                }
            }else{
                nameFile = moment()+'-'+this.arrayIDsObjectOriginalResult[k]['conversationId']+'.txt'
                let JSONconverter = JSON.stringify(this.arrayIDsObjectOriginalResult[k])
                logFile = logFile+JSONconverter
            }
            
            /*var blob = new Blob(logFile, {
                type: "text/plain;charset=utf-8;",
            });*/

            fileDownload(logFile, nameFile, 'text/plain;charset=utf-8;')
            //saveAs(blob, nameFile+".txt");
        },

        async me(t){
            console.log('Token validate: '+t)
            //https://esmtgenesysauth.esmtcx.solutions/
            await axios.get(this.$urlAuth+'genesysme.php?theT='+t, {
                    headers:{
                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log('Credential validation in progress')

                        if(response.data.message!=null){
                            console.log(response.data.message)
                            //return 'No validate'
                        }else{
                            this.tokenActive = true
                        }
                        //return true
                    }).catch(error => {
                        console.log('Credential validation in progress')
                        console.log(error)
                        //return 'Error Network'
                        //return false

                    })
        },
        /*sortedSegments() {
            return this.allSegments.slice()
                    .sort((a, b) => a.timeE - b.timeE);
        },*/
        widthAllClips(type, n){
            if(type=="Segments"){
                if(Object.keys(this.arrayIDsObjects[n].allSegments).length==1){
                    return '100'
                }else if(Object.keys(this.arrayIDsObjects[n].allSegments).length==2){
                    return '50'
                }else if(Object.keys(this.arrayIDsObjects[n].allSegments).length==3){
                    return '33'
                }else if(Object.keys(this.arrayIDsObjects[n].allSegments).length==4){
                    return '25'
                }else if(Object.keys(this.arrayIDsObjects[n].allSegments).length==5){
                    return '20'
                }else{
                    return (100/(Object.keys(this.arrayIDsObjects[n].allSegments).length/2))
                }
            }else{
                if(Object.keys(this.arrayIDsObjects[n].allMetrics).length==1){
                    return '100'
                }else if(Object.keys(this.arrayIDsObjects[n].allMetrics).length==2){
                    return '50'
                }else if(Object.keys(this.arrayIDsObjects[n].allMetrics).length==3){
                    return '33'
                }else if(Object.keys(this.arrayIDsObjects[n].allMetrics).length==4){
                    return '25'
                }else if(Object.keys(this.arrayIDsObjects[n].allMetrics).length==5){
                    return '20'
                }else{
                    return (100/(Object.keys(this.arrayIDsObjects[n].allMetrics).length/3))
                }
            }
            
        },
        segmentsSorted(log) {
            return _.orderBy(log, [item => item.timeS], ['asc']);
        },

        metricsSorted(log) {
            return _.orderBy(log, [item => item.time], ['asc']);
        },

        createAllsegments(){
            this.allSegments = []
        },

        convertTextToArray(){
            if(this.textPasteJSONArray!='' && this.textPasteJSONArray!=null){
                //let textSplit = this.textPasteJSONArray.replace(/ /g, "")
                //console.log(textSplit)
                //let split = textSplit.split(',', '10')
                //this.arrayIDs = split
                //console.log(this.arrayIDs)

                this.consultIDInteractions(this.textPasteJSONArray)
            }   
        },

        async consultIDInteractions(obj){
    
                if(this.textPasteID!='' || this.textPasteID!=null){
                    ///api/v2/analytics/conversations/{id}/details
                    ////api/v2/conversations/chats/
                    await axios.get(this.$urlOrq+'GenesysGetConversations.php?theid='+obj+'&theT='+this.tokenGC, {
                    headers:{
                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        //console.log(response)
                        //console.log(response.data)
                        if(response.data.code=='not.found' || response.data.code=='bad.request'){
                            this.errorConsult = true
                            this.errorConsultText = response.data.message
                            this.textPasteID = ''
                            this.textPasteJSONArray=''
                        }else{
                            console.log(response.data)
                            let arrayIDsObjectsJ = response.data.conversations
                            

                            let num = Object.keys(arrayIDsObjectsJ).length
                            
                            console.log('Cantidad de logs '+num)
    
                            this.sendMultipleIDs(num, arrayIDsObjectsJ)
                            
                            
                            //console.log(arrayIDsObjectsJ)
                            //console.log('Cantidad de logs = '+Object.keys(arrayIDsObjectsJ).length)

                            //this.textPasteJSON = JSON.stringify(response.data)
                            //this.pasteJSONtext()
                        }
                        
                    }).catch(error => {
                        console.error(error)
                        this.errorConsult = true
                        this.errorConsultText = error

                    })
                }   
            
        },

        sendMultipleIDs(n, log){
            let i=0

            let arrayIDsObjectsJ = []
            let parseLog = log

            console.log('//////ParseLog')
            console.log(parseLog)
            //console.log(parseLog.length)
            //return false
            let nu = 0
            let er = 0

            for(i=0; i<n; i++){
                
                if(parseLog[i]!=null){
                    this.$set(arrayIDsObjectsJ, nu, parseLog[i])
                    this.$set(this.arrayIDsObjectOriginalResult, nu, parseLog[i])
                    console.log('Revisando log '+arrayIDsObjectsJ[nu].conversationId)
                    console.log(arrayIDsObjectsJ[nu])
                    console.log(i)
                    this.pasteJSONtext(nu, JSON.stringify(arrayIDsObjectsJ[nu]))
                    nu++
                    
                }else{
                    er++
                }

                if(er<0){
                    this.errorConsult = true
                    this.errorConsultText = er+' IDs no pudieron ser extraidos. Revise los permisos del ClientID/SecretClient.'
                }
                
            }

            console.log(arrayIDsObjectsJ)
        },

        async consultIDInteraction(){
    
                if(this.textPasteID!='' || this.textPasteID!=null){
                    ///api/v2/analytics/conversations/{id}/details
                    ////api/v2/conversations/chats/
                    await axios.get(this.$urlOrq+'GenesysGetConversation.php?theid='+this.textPasteID+'&theT='+this.tokenGC, {
                    headers:{
                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log(response)
                        console.log(response.data)
                        if(response.data.code=='not.found' || response.data.code=='bad.request'){
                            this.errorConsult = true
                            this.errorConsultText = response.data.message
                        }else{
                            //this.textPasteJSON = JSON.stringify(response.data)
                            this.pasteJSONtext(0, JSON.stringify(response.data))
                        }
                        
                    }).catch(error => {
                        console.error(error)
                        this.errorConsult = true
                        this.errorConsultText = error

                    })
                }   
            
        },

        async getAllWrapUpCodes(participantID, conversationID, participant, n){
            ///api/v2/conversations/calls/7b733c7a-bbfe-4c92-9ac1-3096f7b19689/participants/827791e1-825c-4edd-ad47-64f46e89f189/wrapupcodes
            console.log('Buscando códigos de conclusión')
            await axios.get(this.$urlOrq+'GenesysGetConversationWrapups.php?theid='+conversationID+'&theT='+this.tokenGC+'&pID='+participantID, {
                headers:{
                    /*'Authorization': 'Bearer '+this.tokenGC, 
                    'Access-Control-Allow-Origin': '*',
                    'Accept': '*',
                    'Content-Type': 'application/json'*/
                }
                }).then(response => {
                    console.log(response.data)
                    let codigosDeConclusionEjecutados = this.allWrapUpCodes.length

                    this.$set(this.arrayIDsObjects[n].allWrapUpCodes, codigosDeConclusionEjecutados, {'participant':participant, 'wrapup':response.data})

                    //this.pasteJSONtext()

                    }).catch(error => {
                        console.error(error)

                    })
        },
        previewFiles(files) {
            //this.files = this.$refs.myFiles.files[0]
            this.files
            console.log(files)
        },
        async pasteJSONtext(n, log){
            if(n==null){
                n=0
            }

            let nn = parseInt(n)
            //this.myJson = JSON.parse(this.textPasteJSON)
            
            this.logLoaded = true
            console.log('Analisis de log')
            console.log(log)
            console.log(nn)
            this.analizandoLog(nn, log)
            
        },

        pasteJSONtextJ(){
            /*if(n==null){
                n=0
            }*/

            let nn = 0
            this.myJson = this.textPasteJSON
            
            this.logLoaded = true
            console.log('Analisis de log')
            console.log(this.myJson)
            console.log(nn)
            this.analizandoLog(nn, this.myJson)
            
        },

        pasteJSONtext2(){
            this.myJson = this.textPasteJSON
            console.log(this.myJson)
        },

        isObject(obj){
            return Object.prototype.toString.call(obj) === '[object Object]'
        },

        isArray(arr){
            return Array.isArray(arr)
        },

        analizandoLogObj(){
            /// Verificando si la interaccion fue cerrada correctamente
                

                let cerradoCorrectamente = this.textPasteJSON.indexOf("\"disconnectType\": \"client\"")

                console.log('Existe wrapup = '+cerradoCorrectamente);

                if(cerradoCorrectamente>0){
                    this.codigoDeCierreEjecutado = true
                }else{
                    this.codigoDeCierreEjecutado = false
                }

                /// Calculando tiempo total de la interacción
                let initInteraction = this.myJson.conversationStart
                let finishInteraction = this.myJson.conversationEnd

                console.log('Tiempo de finalizacion: '+this.myJson.conversationEnd+' - Tiempo de inicio: '+this.myJson.conversationStart)
                

                let ms = moment(finishInteraction).diff(moment(initInteraction))
                let d = moment.duration(ms)
                this.tiempoInteraccion = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                console.log(d.days() + ':' + d.hours() + ':' + d.minutes() + ':' + d.seconds())
                

                console.log('Tiempo de la interacción: '+this.tiempoInteraccion)

                ////Buscando el TO de la interacción
                //let TO = null
                let i = 0
                let participants = 0
                let agenteCapturado = ''
                let agentesN = 0

                this.arrayIDsObjects[0].tiempoInteraccion = d.hours() + ':' + d.minutes() + ':' + d.seconds()
                                    
                

                //this.allMetrics = this.myJson.participants
                //this.allMetricsN = 100/Object.keys(this.myJson.participants).length

                for(i=0; i<Object.keys(this.myJson.participants).length; i++){

                    let me = 0
                    let ma = 0
                    //let agentsInteractions = 0
                    //let agentsSum = false
                    //let objetoAll = {}
                    for(me=0; me<Object.keys(this.myJson.participants[i].sessions[0].metrics).length; me++){
                        this.allMetrics.push(this.myJson.participants[i].sessions[0].metrics[me])
                        
                        //this.$set(this.allMetrics[this.allMetricsN], 'time', moment(this.myJson.participants[i].sessions[0].metrics[me].emitDate).format("hh:mm:ss"))
                        
                        this.$set(this.allMetrics[this.allMetricsN], 'purpose', this.myJson.participants[i].purpose)
                        this.$set(this.allMetrics[this.allMetricsN], 'time', this.myJson.participants[i].sessions[0].metrics[me].emitDate)
                        
                        if(agenteCapturado != this.myJson.participants[i].userId && this.myJson.participants[i].purpose == 'agent'){
                            agenteCapturado = this.myJson.participants[i].userId
                            agentesN++
                        }

                        if(this.myJson.participants[i].participantName!=null){
                            this.$set(this.allMetrics[this.allMetricsN], 'nParticipant', this.myJson.participants[i].participantName)
                        }

                        if(this.myJson.participants[i].purpose == 'agent'){
                            this.$set(this.allMetrics[this.allMetricsN], 'nAgent', agentesN)
                            this.$set(this.allMetrics[this.allMetricsN], 'remote', this.myJson.participants[i].sessions[0].remote)
                        }
                        
                        this.allMetricsN ++
                        
                    }

                    for(ma=0; ma<Object.keys(this.myJson.participants[i].sessions[0].segments).length; ma++){
                        /*
                        'timeS': this.myJson.participants[i].sessions[0].segments[ma].segmentStart,
                            'timeE': this.myJson.participants[i].sessions[0].segments[ma].segmentEnd

                            'timeS': moment(this.myJson.participants[i].sessions[0].segments[ma].segmentStart).format("hh:mm:ss"),
                            'timeE': moment(this.myJson.participants[i].sessions[0].segments[ma].segmentEnd).format("hh:mm:ss")
                        */
                        this.allSegments.push({
                            'purpose': this.myJson.participants[i].purpose, 
                            'segmentD': this.myJson.participants[i].sessions[0].segments[ma],
                            'timeS': this.myJson.participants[i].sessions[0].segments[ma].segmentStart,
                            'timeE': this.myJson.participants[i].sessions[0].segments[ma].segmentEnd
                            
                        })

                        
                        
                        //this.$set(this.allSegments[this.allSegmentsN], )
                        //this.$set(this.allSegments[this.allSegmentsN], 'timeS', moment(this.myJson.participants[i].sessions[0].segments[ma].segmentStart).format("hh:mm:ss"))
                        //this.$set(this.allSegments[this.allSegmentsN], 'timeE', moment(this.myJson.participants[i].sessions[0].segments[ma].segmentEnd).format("hh:mm:ss"))
                        /*
                        if(agenteCapturado != this.myJson.participants[i].userId && this.myJson.participants[i].purpose == 'agent'){
                            agenteCapturado = this.myJson.participants[i].userId
                            agentesN++
                        }

                        if(this.myJson.participants[i].participantName!=null){
                            this.$set(this.allMetrics[this.allMetricsN], 'nParticipant', this.myJson.participants[i].participantName)
                        }

                        if(this.myJson.participants[i].purpose == 'agent'){
                            this.$set(this.allMetrics[this.allMetricsN], 'nAgent', agentesN)
                            this.$set(this.allMetrics[this.allMetricsN], 'remote', this.myJson.participants[i].sessions[0].remote)
                        }*/
                        
                        this.allSegmentsN ++
                        
                    }
                    

                    if(this.myJson.participants[i].purpose=='agent'){
                        
                        let a = 0
                        this.numeroDeAgentes++
                        
                        let objetoAgentes = {}
                        
                        for(a=0; a<Object.keys(this.myJson.participants[i].sessions[0].segments).length; a++){
                            let finish = this.myJson.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.myJson.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TTAIsum = moment.duration(this.TTAI).add(moment.duration(sum))
                            let TAIsumFormat = moment.utc(TTAIsum.as('milliseconds')).format("HH:mm:ss")

                            this.TTAI = TAIsumFormat

                            if(this.myJson.participants[i].sessions[0].segments[a].segmentType == 'interact'){
                                let TOsum = moment.duration(this.TO).add(moment.duration(sum))
                                let TOsumFormat = moment.utc(TOsum.as('milliseconds')).format("HH:mm:ss")

                                this.TO = TOsumFormat
                                console.log('TO del segmento '+this.myJson.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                                console.log('Sumado tiempo del segmento al TO: '+this.TO)
                            }

                            if(this.myJson.participants[i].sessions[0].segments[a].segmentType == 'wrapup'){
                                this.$set(objetoAgentes, 'wrapup', this.myJson.participants[i].sessions[0].segments[a].wrapUpCode)
                                console.log('Codigo de conclusion encontrado: '+this.myJson.participants[i].sessions[0].segments[a].wrapUpCode)
                                this.getAllWrapUpCodes(this.myJson.participants[i].participantId, this.myJson.conversationId, this.myJson.participants[i].purpose, 0)
                            }

                            /*if(this.myJson.participants[i].sessions[0].segments[a].segmentType == 'hold'){
                                //let TOsum = moment.duration(this.TO).add(moment.duration(sum))
                                //let TOsumFormat = moment.utc(TOsum.as('milliseconds'))

                                let THELD = d

                                this.$set(objetoAgentes, 'THOLD', THELD)
                                
                                console.log('Tiempo en hold de la interacción: '+this.THOLD)
                            }*/

                            if(this.myJson.participants[i].sessions[0].segments[a].segmentType == 'alert' && this.myJson.participants[i].sessions[0].segments[a].disconnectType == 'system'){
                                this.$set(objetoAgentes, 'disconnectType', this.myJson.participants[i].sessions[0].segments[a].disconnectType)
                                console.log('Agente '+a+' desconectado por el sistema')
                            }

                            
                        }

                        let met = 0
                        let tTalkOk = 0
                        for(met=0; met<Object.keys(this.myJson.participants[i].sessions[0].metrics).length; met++){
                                
                                if(this.myJson.participants[i].sessions[0].metrics[met].name == 'tNotResponding'){
                                    this.$set(objetoAgentes, 'tNotResponding', true)
                                    //console.log('Agente '+i+' no contestó la interaccion')

                                    //break
                                }

                                if(this.myJson.participants[i].sessions[0].metrics[met].name == 'tAnswered'){
                                    this.$set(objetoAgentes, 'tAnswered', true)
                                    //console.log('Agente '+i+' contestó la llama')

                                    //break
                                }

                                if(this.myJson.participants[i].sessions[0].metrics[met].name == 'tTalk' && tTalkOk == 0){
                                    tTalkOk++
                                    this.$set(objetoAgentes, 'tTalk', true)
                                }
                        }

                        this.agentsObject.push(objetoAgentes)
                        //this.TO = moment(finish).diff(moment(init));
                    }

                    if(this.myJson.participants[i].purpose=='acd'){
                        let a = 0

                        let objetoACD = {}
                        for(a=0; a<Object.keys(this.myJson.participants[i].sessions[0].segments).length; a++){
                            let finish = this.myJson.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.myJson.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TACDsum = moment.duration(this.TACD).add(moment.duration(sum))
                            let TACDsumFormat = moment.utc(TACDsum.as('milliseconds')).format("HH:mm:ss")

                            this.TACD = TACDsumFormat

                            //console.log('Sumado tiempo del ACD: '+this.TACD)
                            console.log('TO del segmento '+this.myJson.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                            console.log('Sumado tiempo del ACD: '+this.TACD)

                            if(this.myJson.participants[i].sessions[0].segments[a].disconnectType){
                                this.$set(objetoACD, 'disconnectType', this.myJson.participants[i].sessions[0].segments[a].disconnectType)
                            }

                            if(this.myJson.participants[i].sessions[0].segments[a].segmentType=='wrapup'){
                                this.getAllWrapUpCodes(this.myJson.participants[i].participantId, this.myJson.conversationId, this.myJson.participants[i].purpose)
                            }

                            
                        }

                        a = 0

                        for(a=0; a<Object.keys(this.myJson.participants[i].sessions[0].metrics).length; a++){
                            
                            if(this.myJson.participants[i].sessions[0].metrics[a].name == 'tAbandon'){
                                this.$set(objetoACD, 'tAbandon', true)
                                //break
                            }

                            if(this.myJson.participants[i].sessions[0].metrics[a].name == 'tAbandon'){
                                this.$set(objetoACD, 'tAbandon', true)
                                //break
                            }
    
                        }

                        this.acdObject.push(objetoACD)
                    }

                    if(this.myJson.participants[i].purpose=='customer'){
                        let a = 0
                        //let objetoCliente = {}
                        for(a=0; a<Object.keys(this.myJson.participants[i].sessions[0].segments).length; a++){
                            let finish = this.myJson.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.myJson.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TTICsum = moment.duration(this.TTIC).add(moment.duration(sum))
                            let TTICsumFormat = moment.utc(TTICsum.as('milliseconds')).format("HH:mm:ss")

                            this.TTIC = TTICsumFormat

                            //console.log('Sumado tiempo del ACD: '+this.TACD)
                            console.log('TO del segmento '+this.myJson.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                            console.log('Sumado tiempo del ACD: '+this.TTIC)
                            
                            if(this.myJson.participants[i].sessions[0].segments[a].segmentType=='wrapup'){
                                this.getAllWrapUpCodes(this.myJson.participants[i].participantId, this.myJson.conversationId, this.myJson.participants[i].purpose)
                            }
                            
                        }
                    }

                    

                    participants++

                    this.allMetrics.sort(function(a,b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        //return Date.parse(b.emitDate) - Date.parse(a.emitDate);

                        var dateA = new Date(a.emitDate).getTime();
                        var dateB = new Date(b.emitDate).getTime();
                        return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
                    });
                }

                let tAbandonExiste = this.textPasteJSON.indexOf('tAbandon')

                console.log('Existe tAbandon = '+tAbandonExiste);

                if(tAbandonExiste != null){
                    if(this.allMetrics[0].name=='tAbandon'){
                        this.cierreDeInteraccion = 'true'
                        this.interaccionAbandonada = true
                    }else{
                        this.interaccionAbandonada = false
                        this.cierreDeInteraccion = 'no'
                    }
                }
                /*let allNewSegments = null
                allNewSegments = this.allSegments.filter(() => true).sort((d1, d2) => new Date(d1.timeE).getTime() - new Date(d2.timeE).getTime());

                console.log(allNewSegments);*/

                console.log('Número de participantes en la interacción: '+participants)  
            
            
            
        },
        async analizandoLog(nn, log){
            /// Verificando si la interaccion fue cerrada correctamente

                if(nn==null){
                    nn=0
                }

                let n = parseInt(nn)

                ///Objeto nuevo
                this.arrayIDsObjects[n] = {
                    'allMetrics': [],
                    'allSegments': [],
                    'agentsObject': [],
                    'acdObject': [],
                    'allWrapUpCodes': [],
                    'log': JSON.parse(log),
                    'tiempoInteraccion': '00:00:00',
                    'TO': '00.00:00',
                    'TTAI': '00.00:00',
                    'TACD': '00:00:00',
                    'TTIC': '00.00:00',
                    'THOLD': '00.00.00',
                    'mediaType': '',
                    'NUERROR': 0,
                    'callback': false
                    
                }
                
                /// Calculando tiempo total de la interacción
                let initInteraction = this.arrayIDsObjects[n].log.conversationStart
                let finishInteraction = this.arrayIDsObjects[n].log.conversationEnd

                let ms = moment(finishInteraction).diff(moment(initInteraction))
                let d = moment.duration(ms)
                let tiempoInteraccion = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                console.log(d.days() + ':' + d.hours() + ':' + d.minutes() + ':' + d.seconds())
                

                console.log('Tiempo de la interacción: '+tiempoInteraccion)

                this.arrayIDsObjects[n].tiempoInteraccion = tiempoInteraccion
                /*this.arrayIDsObjects[n].TO = '00.00:00'
                this.arrayIDsObjects[n].TTAI = '00.00:00'
                this.arrayIDsObjects[n].TACD = '00:00:00'
                this.arrayIDsObjects[n].TTIC = '00.00:00'*/
            
                //let cerradoCorrectamente = this.textPasteJSON.indexOf("\"disconnectType\": \"client\"")

                //console.log('Existe wrapup = '+cerradoCorrectamente);

                /*if(cerradoCorrectamente>0){
                    this.codigoDeCierreEjecutado = true
                }else{
                    this.codigoDeCierreEjecutado = false
                }*/

                

                
                console.log('Reading conversation ID '+this.arrayIDsObjects[n].log.conversationId)
                console.log('Tiempo de finalizacion: '+this.arrayIDsObjects[n].log.conversationEnd+' - Tiempo de inicio: '+this.arrayIDsObjects[n].log.conversationStart)
                

                

                ////Buscando el TO de la interacción
                //let TO = null
                let i = 0
                let participants = 0
                let agenteCapturado = ''
                let agentesN = 0
                
                this.arrayIDsObjects[n].numeroDeAgentes = 0

                //this.allMetrics = this.myJson.participants
                //this.allMetricsN = 100/Object.keys(this.myJson.participants).length

                let allMetricsNN = 0

                for(i=0; i<Object.keys(this.arrayIDsObjects[n].log.participants).length; i++){

                    let me = 0
                    let ma = 0
                    //let agentsInteractions = 0
                    //let agentsSum = false
                    //let objetoAll = {}
                    for(me=0; me<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics).length; me++){
                        //this.allMetrics.push(this.arrayIDsObjects[0].log.participants[i].sessions[0].metrics[me])

                        
                        //this.$set(this.allMetrics[this.allMetricsN], 'time', moment(this.myJson.participants[i].sessions[0].metrics[me].emitDate).format("hh:mm:ss"))
                        
                        /*this.$set(this.allMetrics[this.allMetricsN], 'purpose', this.arrayIDsObjects[0].log.participants[i].purpose)
                        this.$set(this.allMetrics[this.allMetricsN], 'time', this.arrayIDsObjects[0].log.participants[i].sessions[0].metrics[me].emitDate)
                        */

                        ////Objeto nuevo
                        this.arrayIDsObjects[n].allMetrics.push(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[me])
                        this.$set(this.arrayIDsObjects[n].allMetrics[allMetricsNN], 'purpose', this.arrayIDsObjects[n].log.participants[i].purpose)
                        this.$set(this.arrayIDsObjects[n].allMetrics[allMetricsNN], 'time', this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[me].emitDate)
                        //this.arrayIDsObjects[0].allMetrics[this.allMetricsN] = this.myJson.participants[i].sessions[0].metrics[me]

                        ///Objeto nuevo
                        //this.$set(this.arrayIDsObjects[0].allMetrics[Object.keys(this.arrayIDsObjects[0].allMetrics).length], 'purpose', this.myJson.participants[i].purpose)
                        //this.$set(this.arrayIDsObjects[0].allMetrics[Object.keys(this.arrayIDsObjects[0].allMetrics).length], 'time', this.myJson.participants[i].sessions[0].metrics[me].emitDate)

                        if(agenteCapturado != this.arrayIDsObjects[n].log.participants[i].userId && this.arrayIDsObjects[n].log.participants[i].purpose == 'agent'){
                            agenteCapturado = this.arrayIDsObjects[n].log.participants[i].userId
                            agentesN++
                        }

                        if(this.arrayIDsObjects[n].log.participants[i].participantName!=null){
                            //this.$set(this.allMetrics[this.allMetricsN], 'nParticipant', this.arrayIDsObjects[0].log.participants[i].participantName)
                            this.$set(this.arrayIDsObjects[n].allMetrics[allMetricsNN], 'nParticipant', this.arrayIDsObjects[n].log.participants[i].participantName)
                        }

                        if(this.arrayIDsObjects[n].log.participants[i].purpose == 'agent'){
                            //this.$set(this.allMetrics[allMetricsNN], 'nAgent', agentesN)
                            //this.$set(this.allMetrics[allMetricsNN], 'remote', this.arrayIDsObjects[0].log.participants[i].sessions[0].remote)

                            this.$set(this.arrayIDsObjects[n].allMetrics[allMetricsNN], 'nAgent', agentesN)
                            this.$set(this.arrayIDsObjects[n].allMetrics[allMetricsNN], 'remote', this.arrayIDsObjects[n].log.participants[i].sessions[0].remote)

                        }
                        
                        //this.allMetricsN ++
                        allMetricsNN++
                        
                    }
                    
                    //let allSegmentsN = 0
                    for(ma=0; ma<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments).length; ma++){
                        /*
                        'timeS': this.myJson.participants[i].sessions[0].segments[ma].segmentStart,
                            'timeE': this.myJson.participants[i].sessions[0].segments[ma].segmentEnd

                            'timeS': moment(this.myJson.participants[i].sessions[0].segments[ma].segmentStart).format("hh:mm:ss"),
                            'timeE': moment(this.myJson.participants[i].sessions[0].segments[ma].segmentEnd).format("hh:mm:ss")
                        */
                        /*this.allSegments.push({
                            'purpose': this.myJson.participants[i].purpose, 
                            'segmentD': this.myJson.participants[i].sessions[0].segments[ma],
                            'timeS': this.myJson.participants[i].sessions[0].segments[ma].segmentStart,
                            'timeE': this.myJson.participants[i].sessions[0].segments[ma].segmentEnd
                            
                        })*/

                        ///Objeto nuevo
                        this.arrayIDsObjects[n].allSegments.push({
                            'purpose': this.arrayIDsObjects[n].log.participants[i].purpose, 
                            'segmentD': this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[ma],
                            'timeS': this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[ma].segmentStart,
                            'timeE': this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[ma].segmentEnd
                            
                        })


                        
                        
                        //this.$set(this.allSegments[this.allSegmentsN], )
                        //this.$set(this.allSegments[this.allSegmentsN], 'timeS', moment(this.myJson.participants[i].sessions[0].segments[ma].segmentStart).format("hh:mm:ss"))
                        //this.$set(this.allSegments[this.allSegmentsN], 'timeE', moment(this.myJson.participants[i].sessions[0].segments[ma].segmentEnd).format("hh:mm:ss"))
                        /*
                        if(agenteCapturado != this.myJson.participants[i].userId && this.myJson.participants[i].purpose == 'agent'){
                            agenteCapturado = this.myJson.participants[i].userId
                            agentesN++
                        }

                        if(this.myJson.participants[i].participantName!=null){
                            this.$set(this.allMetrics[this.allMetricsN], 'nParticipant', this.myJson.participants[i].participantName)
                        }

                        if(this.myJson.participants[i].purpose == 'agent'){
                            this.$set(this.allMetrics[this.allMetricsN], 'nAgent', agentesN)
                            this.$set(this.allMetrics[this.allMetricsN], 'remote', this.myJson.participants[i].sessions[0].remote)
                        }*/
                        
                        //this.allSegmentsN ++
                        //allSegmentsNN++
                        
                    }

                    if(this.arrayIDsObjects[n].log.participants[i].sessions[0].mediaType!=null){

                        this.arrayIDsObjects[n].mediaType = this.arrayIDsObjects[n].log.participants[i].sessions[0].mediaType;
                        
                    }
                    

                    if(this.arrayIDsObjects[n].log.participants[i].purpose=='agent'){

                        if(this.arrayIDsObjects[n].log.participants[i].sessions[0].mediaType=='callback'){
                            this.arrayIDsObjects[n].callback  = true
                        }
                        
                        let a = 0
                        this.numeroDeAgentes++
                        this.arrayIDsObjects[n].numeroDeAgentes++
                        
                        let objetoAgentes = {}
                        
                        for(a=0; a<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments).length; a++){
                            let finish = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TTAIsum = moment.duration(this.arrayIDsObjects[n].TTAI).add(moment.duration(sum))
                            let TAIsumFormat = moment.utc(TTAIsum.as('milliseconds')).format("HH:mm:ss")

                            //this.TTAI = TAIsumFormat

                            ///Objeto nuevo
                            this.arrayIDsObjects[n].TTAI = TAIsumFormat

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType == 'interact'){
                                let TOsum = moment.duration(this.arrayIDsObjects[n].TO).add(moment.duration(sum))
                                let TOsumFormat = moment.utc(TOsum.as('milliseconds')).format("HH:mm:ss")

                                //this.TO = TOsumFormat
                                
                                //Objeto nuevo
                                this.arrayIDsObjects[n].TO = TOsumFormat
                                console.log('TO del segmento '+this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                                console.log('Sumado tiempo del segmento al TO: '+this.arrayIDsObjects[n].TO)

                                
                            }

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType == 'hold'){
                                let Tsum = moment.duration(this.arrayIDsObjects[n].THOLD).add(moment.duration(sum))
                                let TsumFormat = moment.utc(Tsum.as('milliseconds')).format("HH:mm:ss")

                                this.arrayIDsObjects[n].THOLD = TsumFormat
                                
                                console.log('Tiempo en hold de la interacción: '+Tsum)
                            }

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType == 'wrapup'){
                                this.$set(objetoAgentes, 'wrapup', this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].wrapUpCode)
                                console.log('Codigo de conclusion encontrado: '+this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].wrapUpCode)
                                this.getAllWrapUpCodes(this.arrayIDsObjects[n].log.participants[i].participantId, this.arrayIDsObjects[n].log.conversationId, this.arrayIDsObjects[n].log.participants[i].purpose, 0)
                            }

                            

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType == 'alert' && this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].disconnectType == 'system'){
                                this.$set(objetoAgentes, 'disconnectType', this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].disconnectType)
                                console.log('Agente '+a+' desconectado por el sistema')
                            }

                            

                            
                        }
                        
                        let met = 0
                        let tTalkOk = 0
                        for(met=0; met<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics).length; met++){
                                
                                if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[met].name == 'tNotResponding'){
                                    this.$set(objetoAgentes, 'tNotResponding', true)
                                    //console.log('Agente '+i+' no contestó la interaccion')

                                    //break
                                }

                                if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[met].name == 'nError'){
                                    let errorCount = this.arrayIDsObjects[n].NUERROR
                                    errorCount = errorCount+1

                                    this.arrayIDsObjects[n].NUERROR = errorCount
                                    //console.log('Agente '+i+' no contestó la interaccion')

                                    //break
                                }

                                if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[met].name == 'tAnswered'){
                                    this.$set(objetoAgentes, 'tAnswered', true)
                                    //console.log('Agente '+i+' contestó la llama')

                                    //break
                                }

                                if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[met].name == 'tTalk' && tTalkOk == 0){
                                    tTalkOk++
                                    this.$set(objetoAgentes, 'tTalk', true)
                                }
                        }

                        this.agentsObject.push(objetoAgentes)

                        this.arrayIDsObjects[n].agentsObject.push(objetoAgentes)
                        //this.TO = moment(finish).diff(moment(init));
                    }

                    if(this.arrayIDsObjects[n].log.participants[i].purpose=='acd'){
                        let a = 0

                        let objetoACD = {}
                        for(a=0; a<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments).length; a++){
                            let finish = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TACDsum = moment.duration(this.TACD).add(moment.duration(sum))
                            let TACDsumFormat = moment.utc(TACDsum.as('milliseconds')).format("HH:mm:ss")

                            this.TACD = TACDsumFormat

                            this.arrayIDsObjects[n].TACD = TACDsumFormat

                            //console.log('Sumado tiempo del ACD: '+this.TACD)
                            console.log('TO del segmento '+this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                            console.log('Sumado tiempo del ACD: '+this.TACD)

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].disconnectType){
                                this.$set(objetoACD, 'disconnectType', this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].disconnectType)
                            }

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType=='wrapup'){
                                this.getAllWrapUpCodes(this.arrayIDsObjects[n].log.participants[i].participantId, this.myJson.conversationId, this.myJson.participants[i].purpose)
                            }

                            
                        }

                        a = 0

                        for(a=0; a<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics).length; a++){
                            
                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[a].name == 'tAbandon'){
                                this.$set(objetoACD, 'tAbandon', true)
                                //break
                            }

                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].metrics[a].name == 'tAbandon'){
                                this.$set(objetoACD, 'tAbandon', true)
                                //break
                            }
    
                        }

                        this.acdObject.push(objetoACD)
                        this.arrayIDsObjects[n].acdObject.push(objetoACD)
                    }

                    if(this.arrayIDsObjects[n].log.participants[i].purpose=='customer' || this.arrayIDsObjects[n].log.participants[i].purpose=='user'){
                        let a = 0
                        //let objetoCliente = {}
                        for(a=0; a<Object.keys(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments).length; a++){
                            let finish = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentEnd
                            let init = this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentStart
                                                        
                            let ms = moment(finish).diff(moment(init));
                            let d = moment.duration(ms);
                            let sum = d.hours() + ':' + d.minutes() + ':' + d.seconds()

                            let TTICsum = moment.duration(this.arrayIDsObjects[n].TTIC).add(moment.duration(sum))
                            let TTICsumFormat = moment.utc(TTICsum.as('milliseconds')).format("HH:mm:ss")

                            this.TTIC = TTICsumFormat

                            this.arrayIDsObjects[n].TTIC = TTICsumFormat

                            //console.log('Sumado tiempo del ACD: '+this.TACD)
                            console.log('TO del segmento '+this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType+': '+sum)
                            console.log('Sumado tiempo del ACD: '+this.arrayIDsObjects[n].TTIC)
                            
                            if(this.arrayIDsObjects[n].log.participants[i].sessions[0].segments[a].segmentType=='wrapup'){
                                this.getAllWrapUpCodes(this.arrayIDsObjects[n].log.participants[i].participantId, this.myJson.conversationId, this.myJson.participants[i].purpose)
                            }
                            
                        }
                    }

                    

                    participants++

                    /*this.allMetrics.sort(function(a,b){
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        //return Date.parse(b.emitDate) - Date.parse(a.emitDate);

                        var dateA = new Date(a.emitDate).getTime();
                        var dateB = new Date(b.emitDate).getTime();
                        return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
                    });*/
                }

                let tAbandonExiste = this.arrayIDsObjects[n].log.participants.indexOf('tAbandon')

                console.log('Existe tAbandon = '+tAbandonExiste);

                

                if(tAbandonExiste>0){

                    this.arrayIDsObjects[n].tAbandonExist = true

                    /*if(this.allMetrics[0].name=='tAbandon'){
                        this.cierreDeInteraccion = 'true'
                        this.interaccionAbandonada = true
                    }else{
                        this.interaccionAbandonada = false
                        this.cierreDeInteraccion = 'no'
                    }*/
                }
                /*let allNewSegments = null
                allNewSegments = this.allSegments.filter(() => true).sort((d1, d2) => new Date(d1.timeE).getTime() - new Date(d2.timeE).getTime());

                console.log(allNewSegments);*/

                console.log('Número de participantes en la interacción: '+participants)  
            
            
            
        }
    },
    mounted(){

        
        /*if(Object.keys(this.myJson).length>0){
            this.analizandoLog()
        }*/

        this.auth = true

        this.tokenGC=''
        this.theApp = {}
        this.myJson = {}
        this.textPasteJSON = null
        this.textPasteID = null
        let theAppLocal = {}

        if(localStorage.getItem('esmtAppLogs')==null){
                this.$router.push({ name: 'Auth' })
        }else{

            theAppLocal = JSON.parse(localStorage.getItem('esmtAppLogs'))
                
            let validarAuth = this.me(theAppLocal.t)

            console.log(validarAuth)

                //if(this.tokenActive ==true){
                    this.theApp.name = theAppLocal.name
                    this.theApp.description = theAppLocal.description
                    this.theApp.state = theAppLocal.state

                    this.tokenGC = theAppLocal.t

                    this.auth = true
                //}else{
                    //this.auth = false
                    //this.$router.push({ name: 'Home' })
                //}

                

        }
    },
}
</script>
<style scoped>
    /*.v-icon, .v-list-item__icon{
        width: 0px !important;
        margin-left: 0px !important;
    }
    .v-list-item__icon{
        margin-left: 0px !important;
        text-align: left  !important;
    }*/

    /* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}

/*.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}*/

    .timeline{
        width:100%;
        height:100px;
        background-color:#dedede;
        display:block;
        white-space: nowrap;
        overflow-x: auto;
        margin: 0;
        padding: 0;
        font-size: 0;
    }

    .timeline>.clip{
        display:inline-block;
        height:90px;
        position: relative;
        border: 1px inset #FFF;
        font-size:10px;
        overflow: hidden;
    }
    .timeline>.clip>.clip-p{
        margin: 0px !important;
        padding-left: 5px;
    }

    .v-list{
            padding: 0px 0px;

    }

    .v-list-item__title, .v-list-item__subtitle {
        white-space: normal;
    }

    .v-list-group__items{
        background-color:aliceblue;
    }

    .v-list-item {
        min-height: auto;
    }

    .v-list .v-list-item--active{
        background-color:#f3eded !important;
    }
</style>