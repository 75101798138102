<template>
    <v-container class="text-center">
        <p class="h2">{{mensajeValidacion}}</p>
    </v-container>
</template>
<script>
import axios from 'axios'
export default {
    name:'token',
    methods:{
        async me(t){
            console.log('Token validate: '+t)
            //https://esmtgenesysauth.esmtcx.solutions/
            await axios.get('https://esmtgenesysauth.esmtcx.solutions/genesysme.php?theT='+t, {
                    headers:{
                        /*'Authorization': 'Bearer '+this.tokenGC, 
                        'Access-Control-Allow-Origin': '*',
                        'Accept': '*',
                        'Content-Type': 'application/json'*/
                    }
                    }).then(response => {
                        console.log('Credential validation in progress')

                        if(response.data.message!=null){
                            console.log(response.data.message)
                            this.tokenActive = false
                            //return 'No validate'
                        }else{
                            console.log(response.data)
                            this.tokenActive = true
                        }
                        //return true
                    }).catch(error => {
                        console.log('Credential validation in progress')
                        console.log(error)
                        this.tokenActive = false
                        //return 'Error Network'
                        //return false

                    })
        },

        lsExist(){
            let exist = localStorage.getItem('esmtAppLogs')

            if(exist!=null){
                console.log('LS session exist')
                this.localStorageExist = true
            }
        },
    },
    data() {
        return {
            tokenActive: null,
            localStorageExist: null,
            mensajeValidacion: 'Validando Organización, por favor espere...'
        }
    },
    watch:{
      tokenActive: function(newValue) {

                if(newValue!=null){
                  if(newValue == true){
                        this.$router.push({ name: 'App' })
                    }else{
                        this.$router.push({ name: 'Auth' })
                    }
                }else{
                    this.mensajeValidacion = 'No se ha podido validar la App, intentalo nuevamente o contacta con el administrador.'
                }

      },
              
    },
    mounted(){
        let theAppLocal = {}

        if(localStorage.getItem('esmtAppLogs')==null){
                this.$router.push({ name: 'Auth' })
        }else{

                theAppLocal = JSON.parse(localStorage.getItem('esmtAppLogs'))
                
                this.lsExist()

                if(this.localStorageExist){
                    this.me(theAppLocal.t)
                }else{
                    this.auth = false
                    this.$router.push({ name: 'Auth' })
                }
   

        }
    }
}
</script>